<div class="profile-avatar" [matMenuTriggerFor]="profileMenu">
  {{ userInitials | uppercase }}
</div>

<mat-menu #profileMenu="matMenu">
  <button mat-menu-item (click)="onLogout()">
    <mat-icon>logout</mat-icon>Logout
  </button>
  <div class="app-version">Litmus (v - {{ version }})</div>
</mat-menu>
