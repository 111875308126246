import { Injectable } from '@angular/core';
import { SessionService } from '@features/session/session.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiSSEService {
  constructor(private session: SessionService) {}

  get accessToken() {
    return this.session?.accessToken || '';
  }

  public postS(url: string, payload: any): Observable<any> {
    const headers = new Headers({
      Authorization: `Bearer ${this.accessToken}`,
      'Content-Type': 'application/json', // Adjusted content type
    });

    return new Observable<any>((observer) => {
      fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
      })
        .then((response) => {
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let buffer = '';

          function readData() {
            return reader.read().then(({ value, done }) => {
              if (value) {
                buffer += decoder.decode(value, { stream: !done });

                // Split the buffer by newline characters, which indicate separate SSE events
                const parts = buffer.split('\n');
                buffer = parts.pop(); // Keep the incomplete part in the buffer

                // Process each part as an SSE event
                parts.forEach((part) => {
                  if (part.startsWith('data:')) {
                    // Remove the "data:" prefix and trim whitespace
                    const data = part.substring(5);
                    observer.next(data); // Emit the data
                  }
                });
              }

              if (done) {
                observer.complete(); // Complete the observable when the stream ends
                return;
              }

              return readData();
            });
          }

          return readData();
        })
        .catch((error) => {
          console.error('Error:', error);
          observer.error(error); // Emit error if fetch fails
        });
    });
  }
}
