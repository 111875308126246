import { Injectable, Component, Inject } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

type AlertType = 'error' | 'warning' | 'success';

@Component({
  selector: 'app-snackbar-component',
  template: `
    <div class="snackbar-content">
      {{ data?.message }}
      <button mat-icon-button class="close-button" (click)="closeSnackbar()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .snackbar-content {
        display: flex;
        align-items: center;
        margin-right: 1rem;
      }

      .close-button {
        color: #fff !important ;
        position: absolute;
        top: 0;
        right: 0;
      }
    `,
  ],
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  closeSnackbar() {
    this.snackBarRef.dismiss();
  }
}

@Injectable({ providedIn: 'root' })
export class AlertService {
  private activeAlerts: Map<string, MatSnackBarRef<SnackbarComponent>> =
    new Map();

  constructor(private snackbar: MatSnackBar) {}

  showAlert(
    message: string,
    type: AlertType,
    duration = 3000,
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    horizontalPosition: MatSnackBarHorizontalPosition = 'end'
  ) {
    const alertKey = `${message}-${type}`;

    // Check if an alert with the same message and type is already active
    if (this.activeAlerts.has(alertKey)) {
      return; // Don't open a new alert if one already exists
    }

    const config: MatSnackBarConfig = {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: type,
    };

    const snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, {
      data: { message },
      ...config,
    });

    // Store the reference to the active alert
    this.activeAlerts.set(alertKey, snackbarRef);

    // Remove the alert from activeAlerts when it's dismissed
    snackbarRef.afterDismissed().subscribe(() => {
      this.activeAlerts.delete(alertKey);
    });
  }

  closeAllAlerts() {
    this.activeAlerts.forEach((snackbarRef) => {
      snackbarRef.dismiss();
    });
    this.activeAlerts.clear();
  }
}
